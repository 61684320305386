import styled, { css } from "styled-components";

const switchWidth = "48px";
const switchHeight = "32px";
const switchRadius  = "256px";
const knobWidth  = "26px";
const knobHeight  = "26px";
const knobGap = "4px";
const knobRadius = "100%";

const disabledStyles = css`
  & + label {
    cursor: default;

    &:before {
      background-color: ${props => props.theme.colors.gray4};
    }

    &:after {
      background-color: ${props => props.theme.colors.white};
      opacity: 0.4;
    }
  }
`;

export const Toggle = styled.span`
  position: relative;
  box-sizing: border-box;
  display: inline-grid;
  align-items: center;
  width: ${switchWidth};
  height: ${switchHeight};
  vertical-align: middle;
  margin: 0;

  input[type="checkbox"] {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;

    & + label {
      display: inline-grid;
      box-sizing: border-box;
      align-items: center;
      outline: none;
      user-select: none;
      width: ${switchWidth};
      height: ${switchHeight};
      border-radius: ${switchRadius};
      cursor: pointer;
      transition: background ease-out 0.3s;

      &:before {
        content: "";
        display: block;
        position: absolute;
        border-radius: ${switchRadius};
        width: ${switchWidth};
        height: ${switchHeight};
        background-color: ${props => props.theme.colors.gray4};
        left: 0;
      }

      &:after {
        display: block;
        position: absolute;
        content: "";
        width: ${knobWidth};
        height: ${knobHeight};
        border-radius: ${knobRadius};
        background-color: ${props => props.theme.colors.white};
        transition: all ease-out 0.4s;
        margin-left: ${knobGap};
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
      }
    }

    &:disabled {
      ${disabledStyles}
    }

    &:checked {
      & + label {
        &:before {
          background-color: ${props => props.theme.colors.success};
        }
        &:after {
          margin-left: calc(100% - ${knobWidth} - ${knobGap});
          transition: all ease-out 0.2s;
          background-color: ${props => props.theme.colors.white};
        }
      }

      &:disabled {
        ${disabledStyles}
      }
    }
  }
`;
