import React from "react";
import * as S from "./Toggle.styled";

type Props = {
  name: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const Toggle = ({
  name,
  checked = false,
  disabled = false,
  onChange,
}: Props) => {
  const handleChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    onChange && onChange(target.checked);
  };

  return (
    <S.Toggle>
      <input
        onChange={handleChange}
        type="checkbox"
        id={name}
        name={name}
        disabled={disabled}
        checked={checked}
      />
      <label htmlFor={name} />
    </S.Toggle>
  );
};

export default Toggle;
