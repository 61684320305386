import styled from "styled-components";

const customBreakpoint = '380px';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.md}) and (min-width: ${props => props.theme.breakpoints.xs}) {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: ${customBreakpoint}) {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
`;

export const LabelContainer = styled.span`
  margin: 0 4px 0 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) and (min-width: ${props => props.theme.breakpoints.xs}) {
    margin: 4px 0;
  }

  @media (max-width: ${customBreakpoint}) {
    margin: 4px 0;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  display: block;
  margin: 4px 8px 4px 0;
  font-weight: 400;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) and (min-width: ${props => props.theme.breakpoints.xs}) {
    margin: 0;
  }
`;

export const DiscountInfo = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  height: 22px;
  white-space: nowrap;
  padding: 0 6px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.success};
  color: ${props => props.theme.colors.white};
`;
