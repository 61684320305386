import styled from "styled-components";

const borderColor = 'rgba(0, 0, 0, .08)';

export const PricingContainer = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    overflow-x: auto;
    padding-bottom: 24px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border: 0;
  border-spacing: 0;

  th:first-child,
  td:first-child {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      left: 0;
      position: sticky;
      background: ${props => props.theme.colors.white};

      &:stuck {
        background: red;
      }
    }
  }

  th {
    text-align: center;
    vertical-align: top;
    border-bottom: 1px solid ${borderColor};
    
    &:first-child {
      text-align: left;
    }

    &:nth-child(3) {
      background-color: ${props => props.theme.colors.primaryLight};
    }
  }

  td {
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid ${borderColor};

    &:first-child {
      font-weight: 400;
      text-align: left;
    }

    &:nth-child(3) {
      background-color: ${props => props.theme.colors.primaryLight};
    }

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      font-size: 14px;
    }
  }

  tr {
    &:first-child th:nth-child(3) {
      border-radius: 8px 8px 0 0;
    }

    &:last-child td:nth-child(3) {
      border-radius: 0 0 8px 8px;
    }
  
    &:last-child td {
      border-bottom: none;
      vertical-align: top;

      button {
        margin-top: 40px;
      }
    }
  }
`;

export const Cell = styled.div`
  max-width: calc(20vw - 20px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: none;
    width: 30vw;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 45vw;
  }

  th & {
    padding: 48px 32px 24px;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 48px 16px 24px;
    }
  }

  th:first-child & {
    padding: 48px 16px 24px 0;
    text-align: left;
    align-items: flex-start;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      padding: 48px 16px 24px 20px;
    }
  }

  td & {
    padding: 16px 32px;
    text-align: center;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 16px;
    }
  }

  td:first-child & {
    padding: 16px 16px 16px 0;
    text-align: left;
    align-items: flex-start;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      padding: 16px 16px 16px 20px;
    }
  }

  th:last-child &, td:last-child & {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding-right: 20px;
    }
  }

  tr:last-child td & {
    padding-bottom: 48px;
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const PlanName = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 16px;
  }
`;

export const PlanPrice = styled.span`
  display: block;
  font-size: 32px;
  color: ${props => props.theme.colors.gray0};
  font-family: ${props => props.theme.fonts.secondary};
  line-height: 120%;
  font-weight: 600;
  margin: 14px 0 4px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 24px;
  }
`;

export const PlanCaption = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.colors.gray2};
  line-height: 150%;
  margin-bottom: 24px;
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    font-size: 12px;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;