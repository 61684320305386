import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./PricingTable.styled";
import PeriodToggler, { BillingPeriod, YEARLY_DISCOUNT } from "./PeriodToggler";
import Button from "../ui-kits/Button";

import yesIcon from "../../img/check.svg";
import noIcon from "../../img/x.svg";

const Icon = ({ type }: { type: "yes" | "no" }) =>  (
  <S.Icon>
    <img src={type === "yes" ? yesIcon : noIcon} title={type} alt={type} />
  </S.Icon>
);

const Yes = () => <Icon type="yes" />;
const No = () => <Icon type="no" />;

const TableCell = ({
  children,
  as: tableCellHtmlElement = "td"
}: {
  children: React.ReactNode;
  as?: 'td' | 'th';
}) => {
  const TC = tableCellHtmlElement as React.ElementType<any>;
  return <TC><S.Cell>{children}</S.Cell></TC>;
};

const createPriceString = (priceNumber: number) =>
  "$" + priceNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");


const PricingTable = () => {
  const { t } = useTranslation();
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("monthly");

  const getPrice = useCallback((monthlyPrice: number) => {
    let price = monthlyPrice;

    if (billingPeriod === "yearly") {
      const yearlyWithoutDiscount = monthlyPrice * 12;
      price = yearlyWithoutDiscount - yearlyWithoutDiscount * YEARLY_DISCOUNT / 100;
    }

    return createPriceString(price);
  }, [billingPeriod]);

  return (
    <S.PricingContainer>
      <S.Table>
        <thead>
          <tr>
            <TableCell as="th">
              <PeriodToggler
                value={billingPeriod}
                onChange={setBillingPeriod}
              />
            </TableCell>
            <TableCell as="th">
              <S.PlanName>
                {t("pricing.plans.0")}
              </S.PlanName>
              <S.PlanPrice>
                {t("pricing.free")}
              </S.PlanPrice>
              <S.PlanCaption>
                {t("pricing.noCreditCard")}
              </S.PlanCaption>
              <Button fullWidth>
                {t("pricing.cta.signUp")}
              </Button>
            </TableCell>
            <TableCell as="th">
              <S.PlanName>
                {t("pricing.plans.1")}
              </S.PlanName>
              <S.PlanPrice>
                {getPrice(29.99)}
              </S.PlanPrice>
              <S.PlanCaption>
                {t(`pricing.periods.${billingPeriod}`)}
              </S.PlanCaption>
              <Button fullWidth>
                {t("pricing.cta.tryForFree")}
              </Button>
            </TableCell>
            <TableCell as="th">
            <S.PlanName>
                {t("pricing.plans.2")}
              </S.PlanName>
              <S.PlanPrice>
                {getPrice(59.99)}
              </S.PlanPrice>
              <S.PlanCaption>
              {t(`pricing.periods.${billingPeriod}`)}
              </S.PlanCaption>
              <Button fullWidth>
                {t("pricing.cta.tryForFree")}
              </Button>
            </TableCell>
            <TableCell as="th">
              <S.PlanName>
                {t("pricing.plans.3")}
              </S.PlanName>
              <S.PlanPrice>
                {t("pricing.custom")}
              </S.PlanPrice>
              <S.PlanCaption>
                {t("pricing.tailored")}
              </S.PlanCaption>
              <Button type="secondary" fullWidth>
                {t("pricing.cta.contactSales")}
              </Button>
            </TableCell>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>{t("pricing.features.maxAppointmentsOnlinePerMonth")}</TableCell>
            <TableCell>20</TableCell>
            <TableCell>100</TableCell>
            <TableCell>200</TableCell>
            <TableCell>{t("pricing.custom")}</TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.maxAppointmentsOnline")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.locationsNumber")}</TableCell>
            <TableCell>1</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.specialistsNumber")}</TableCell>
            <TableCell>1</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.teamSize")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.maxAppointmetsPerMonth")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
            <TableCell>{t("pricing.unlimited")}</TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.emailNotif")}</TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.smsNotif")}</TableCell>
            <TableCell><No /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.specialistsPics")}</TableCell>
            <TableCell><No /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.premiumSupport")}</TableCell>
            <TableCell><No /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.onboardingCall")}</TableCell>
            <TableCell><No /></TableCell>
            <TableCell><No /></TableCell>
            <TableCell><Yes /></TableCell>
            <TableCell><Yes /></TableCell>
          </tr>
          <tr>
            <TableCell>{t("pricing.features.marketingSupport")}</TableCell>
            <TableCell>
              <No />
              <Button fullWidth>
                {t("pricing.cta.signUp")}
              </Button>
            </TableCell>
            <TableCell>
              <No />
              <Button fullWidth>
                {t("pricing.cta.tryForFree")}
              </Button>
            </TableCell>
            <TableCell>
              <Yes />
              <Button fullWidth>
                {t("pricing.cta.tryForFree")}
              </Button>
            </TableCell>
            <TableCell>
              <Yes />
              <Button type="secondary" fullWidth>
                {t("pricing.cta.contactSales")}
              </Button>
            </TableCell>
          </tr>
        </tbody>
      </S.Table>
    </S.PricingContainer>
  );
};

export default PricingTable;