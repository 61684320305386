import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Toggle from "../ui-kits/Toggle";
import * as S from "./PeriodToggler.styled";

export const YEARLY_DISCOUNT = 10;

export type BillingPeriod = "monthly" | "yearly";

type Props = {
  value: BillingPeriod,
  onChange: (value: BillingPeriod) => void;
}

const PeriodToggler = ({ value, onChange: onChangeProp }: Props ) => {
  const { t } = useTranslation();
  const [isYearly, setYearly] = useState<boolean>(value === "yearly");

  useEffect(() => {
    if (value) {
      setYearly(value === "yearly");
    }
  }, [value]);

  const onChange = useCallback((newIsYearly: boolean) => {
    onChangeProp(newIsYearly ? "yearly" : "monthly");
  }, [onChangeProp]);

  return (
    <S.Container>
      <Toggle
        name="billingPeriod"
        checked={isYearly}
        onChange={onChange}
      />
      <S.LabelContainer>
        <S.Label>{t("pricing.periods.yearly")}</S.Label>
        <S.DiscountInfo>{YEARLY_DISCOUNT}% {t("pricing.discount")}</S.DiscountInfo>
      </S.LabelContainer>
    </S.Container>
  )
}

export default PeriodToggler;