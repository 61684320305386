import React, { useEffect, useState } from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../../components/layout/Layout";
import PageTitle from "../../components/layout/PageTitle";
import PageWrapper from "../../components/layout/PageWrapper";
import PricingTable from "../../components/pricing/PricingTable";

const PricingPage = ({ location }: PageProps) => {
  const { t } = useTranslation();

  return (
    <Layout location={location}>
      <PageWrapper noSpacingHorizontal>
        <PageTitle
          spacingHorizontal={20}
          heading={t("pricing.heading")}
          paragraph={t("pricing.paragraph")}
        />
        <PricingTable />
      </PageWrapper>
    </Layout>
  );
};

export default PricingPage;
